import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/common/Seo';
import Centerpiece from '../components/pages/contact/Centerpiece';
import localize from '../components/common/localize';

function Contact({ data }) {
  const { page } = data;
  return (
    <>
      <SEO title={page.title} description={page.description} />
      <Centerpiece
        title={page.heroTitle}
        titleTranslations={page.heroTitleTranslations}
        image={page.heroImage}
        questions={page.questions}
        career={page.career}
        social={page.social}
        address={page.address}
        email={page.email}
        careersEmail={page.careersEmail}
        officeAddress={page.officeAddress}
        socialMedia={page.socialMedia}
      />
    </>
  );
}

export default localize(Contact);

export const query = graphql`
  query {
    page: sanityContact {
      title {
        localized
      }
      description {
        localized
      }
      heroTitle {
        localized
      }
      heroTitleTranslations: heroTitle {
        en
        fr
      }
      heroImage {
        mobileImage: image {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 700
              height: 300
              placeholder: BLURRED
            )
          }
        }
        desktopImage: image {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 540
              height: 616
              placeholder: BLURRED
            )
          }
        }
        alt {
          localized
        }
      }
      questions {
        localized
      }
      career {
        localized
      }
      social {
        localized
      }
      address {
        localized
      }
      email
      careersEmail
      officeAddress
      socialMedia {
        url
        name
      }
    }
  }
`;

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};
