import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

import Grid from '../../common/Grid';
import UnstyledList from '../../common/UnstyledList';
import { H1, H4, P } from '../../common/Typography';

import { minWidth, maxWidth, betweenWidth } from '../../../utils/breakpoints';
import { breakpoints } from '../../../utils/theme';
import { fadeIn, fadeInRight } from '../../../utils/transitions';
import useWaypoint from '../../../hooks/useWaypoint';

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.navy};
  background: ${({ theme }) => theme.colors.cream};
  overflow: hidden;
`;

const Title = styled(H1)`
  ${maxWidth('contactDesktop')} {
    grid-column: col / span 12;
    position: relative;
    z-index: 1;
  }
  ${minWidth('contactDesktop')} {
    grid-column: col / span 7;
  }
  span {
    display: block;
  }
`;

const ImgWrapper = styled.div`
  ${maxWidth('contactDesktop')} {
    margin-top: -45vw;
    margin-top: -30vw;
    position: relative;
    grid-column: col 3 / span 10;
    height: 300px;
    width: 100%;
  }
  ${minWidth('contactDesktop')} {
    grid-column: col 9 / span 4;
    grid-row: 1 / span 3;
    max-height: 100%;
  }
`;

const Img = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ContactMethod = styled.div`
  ${maxWidth('contactTablet')} {
    grid-column: col / span 12;
  }
  ${betweenWidth('contactTablet', 'contactDesktop')} {
    &:nth-child(odd) {
      grid-column: col / span 6;
    }
    &:nth-child(even) {
      grid-column: span 6;
    }
  }
  ${minWidth('contactDesktop')} {
    &:nth-child(odd) {
      grid-column: col / span 4;
    }
    &:nth-child(even) {
      grid-column: span 4;
    }
  }
`;

export default function Centerpiece({
  address,
  career,
  careersEmail,
  className,
  email,
  image,
  officeAddress,
  questions,
  social,
  socialMedia,
  titleTranslations,
  title,
}) {
  const [ref, visible] = useWaypoint();
  const images = withArtDirection(
    getImage(image.mobileImage.asset.gatsbyImageData),
    [
      {
        image: getImage(image.desktopImage.asset.gatsbyImageData),
        media: `(min-width: ${breakpoints.contactDesktop}px)`,
      },
    ]
  );
  return (
    <Wrapper ref={ref} className={className} paddedTop paddedBottom>
      <Title aria-label={title}>
        <span aria-hidden="true" css={fadeInRight(visible)}>
          {titleTranslations.fr}
        </span>
        <span aria-hidden="true" css={fadeInRight(visible, 2)}>
          {titleTranslations.en}
        </span>
      </Title>
      <ImgWrapper active={visible} order={3} direction="left">
        <Img image={images} alt={image.alt} />
      </ImgWrapper>
      <ContactMethod css={fadeIn(visible, 3)}>
        <H4 as="h2">{questions}</H4>
        <P as="a" href={`mailto:${email}`}>
          {email}
        </P>
      </ContactMethod>
      <ContactMethod css={fadeIn(visible, 3)}>
        <H4 as="h2">{career}</H4>
        <P as="a" href={`mailto:${careersEmail}`}>
          {careersEmail}
        </P>
      </ContactMethod>
      <ContactMethod css={fadeIn(visible, 3)}>
        <H4 as="h2">{social}</H4>
        <UnstyledList>
          {socialMedia.map((lifeSuckingTrash, i) => (
            <li key={i}>
              <P
                as="a"
                href={lifeSuckingTrash.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lifeSuckingTrash.name}
              </P>
            </li>
          ))}
        </UnstyledList>
      </ContactMethod>
      <ContactMethod css={fadeIn(visible, 3)}>
        <H4 as="h2">{address}</H4>
        <P as="address">{officeAddress}</P>
      </ContactMethod>
    </Wrapper>
  );
}

Centerpiece.propTypes = {
  address: PropTypes.string.isRequired,
  career: PropTypes.string.isRequired,
  careersEmail: PropTypes.string.isRequired,
  className: PropTypes.string,
  email: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  titleTranslations: PropTypes.object.isRequired,
  officeAddress: PropTypes.string.isRequired,
  questions: PropTypes.string.isRequired,
  social: PropTypes.string.isRequired,
  socialMedia: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
