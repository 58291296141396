import { useContext, useState, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { store } from '../context/store';

export default function useWaypoint({
  threshold = 0.25,
  triggerOnce = true,
  waitForPageTransition = true,
} = {}) {
  const { pageTransitioned } = useContext(store).state;
  const [transitioned, setTransitioned] = useState(false);

  useMemo(() => {
    if (pageTransitioned) {
      setTransitioned(true);
    }
    if (!pageTransitioned && !waitForPageTransition) {
      setTransitioned(false);
    }
  }, [pageTransitioned, waitForPageTransition]);

  const [ref, visible] = useInView({ threshold, triggerOnce });
  return [ref, visible && transitioned];
}
